import { useState } from 'react';
import { Box, Typography, Paper, IconButton, Dialog } from '@mui/material';
import { ExpenseFolderPdf } from '../Models/ExpenseFolderPdf';
import { base64ToPDFFile, downloadFile } from '../Utils/File';
import ResizingButton from './Shared/ResizingButton';
import { FileDownloadOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { formatNumberWithSpaces } from './PdfDocument';
import ApiClient from '../Services/ApiClient';
import { useAppSelector } from '../Redux/Hooks';

type PdfBoxProps = {
	title: string;
	dateRange: string;
	total: number;
	expenseFolderPdf: ExpenseFolderPdf;
};

export const PdfBox = ({
	title,
	dateRange,
	total,
	expenseFolderPdf,
}: PdfBoxProps) => {
	const [open, setOpen] = useState(false);

	const apiClient = useAppSelector((state) =>
		ApiClient(state.apiKey.value ?? ''),
	);

	const { t } = useTranslation();

	return (
		<>
			<Box
				display="flex"
				flexDirection={'column'}
				justifyContent="space-between"
			>
				<Paper sx={{ padding: 4 }}>
					<Box display="flex">
						<Box flexGrow={1}>
							<Typography variant="h6">{title}</Typography>
							<Typography variant="body2">{dateRange}</Typography>
						</Box>

						<Box>
							<IconButton
								onClick={() => {
									const data =
										expenseFolderPdf.source ===
										'TravelExpense'
											? apiClient.expense.getExpenseFolderPdfTravelExpenseDetail(
													expenseFolderPdf.id,
											  )
											: apiClient.expense.getExpenseFolderPdfDetail(
													expenseFolderPdf.id,
											  );
									data.then((efp) => {
										downloadFile(
											base64ToPDFFile(
												efp.data.data!,
												expenseFolderPdf.description,
											),
										);
									});
								}}
							>
								<FileDownloadOutlined />
							</IconButton>
						</Box>
					</Box>

					<Box
						display="flex"
						fontWeight="700"
						marginTop={3}
						paddingBottom={1}
						borderBottom={'1px solid'}
						borderColor={'grey.100'}
					>
						<Typography fontWeight="inherit" flexGrow={1}>
							{t('total')}
						</Typography>
						<Typography fontWeight="inherit">
							{formatNumberWithSpaces(total)} {expenseFolderPdf.currencyCode}
						</Typography>
					</Box>
				</Paper>
			</Box>

			<Dialog open={open} onClose={() => setOpen(false)}>
				<iframe
					src={expenseFolderPdf.data}
					width="100%"
					height="700pxp"
					title="pdf"
				/>
				<ResizingButton
					color="primary"
					variant="contained"
					onClick={() => setOpen(false)}
				>
					Close
				</ResizingButton>
			</Dialog>
		</>
	);
};
