import {
	Dialog,
	DialogTitle,
	DialogContent,
	Box,
	Typography,
	Alert,
	Link,
	Stack,
} from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import CloseIconButtonComponent from './Shared/CloseIconButtonComponent';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ExpenseFolder } from '../Models/ExpenseFolder';
import { useAppDispatch, useAppSelector } from '../Redux/Hooks';
import { concatExpenses } from '../Utils/ConcatExpenses';
import { pdf } from '@react-pdf/renderer';
import { PdfDocument } from './PdfDocument';
import ApiClient from '../Services/ApiClient';
import {
	closeSendFolderDialog,
	setEmailSuccessFullySent,
} from '../Redux/Uislice';
import { findDateRange } from '../Utils/FindDateRange';
import { getExpenseFolderPdfs } from '../Redux/ExpenseFolderPdfsSlice';
import { useTranslation } from 'react-i18next';
import { matomo } from '../MatomoTracker';
import { calculateTotal, withExhange } from '../Utils/CalculateTotal';
import { removeSentFolder } from '../Redux/ExpenseFoldersSlice/ExpenseFoldersSlice';

const SendFolderDialogComponent = () => {
	const [isSending, setIsSending] = useState(false);
	const folder = useAppSelector((state) =>
		state.expenseFolderReducer.folders.find(
			(folder) => folder.id === state.uiReducer.idOfFolderToSend,
		),
	);
	const navigate = useNavigate();
	const open = useAppSelector(
		(state) => state.uiReducer.isSendFolderDialogOpen,
	);
	const token = useAppSelector((state) => state.apiKey.value);
	const dispatch = useAppDispatch();

	const submit = async () => {
		setIsSending(true);
		await handleSubmitExpenseFolder(folder!, token!)
			.then(async () => {
				onClose();
				dispatch(setEmailSuccessFullySent(true));
				dispatch(removeSentFolder({ sentFolderId: folder!.id }));
				dispatch(getExpenseFolderPdfs());
				await new Promise((resolve) => setTimeout(resolve, 5000));
				dispatch(setEmailSuccessFullySent(null));
				navigate('/history');
			})
			.catch((e) => {
				onClose();
				setIsSending(false);
				dispatch(setEmailSuccessFullySent(false));
			});
	};
	const profile = useAppSelector((state) => state.profileReducer.profile);

	const { t } = useTranslation();

	const onClose = () => {
		dispatch(closeSendFolderDialog());
	};

	const handleSubmitExpenseFolder = async (
		fs: ExpenseFolder,
		token: string,
	): Promise<void> => {
		const apiService = ApiClient(token);

		const folder = await withExhange(fs, token, profile);
		const total = await calculateTotal(folder, apiService, profile);
		console.log(folder);

		const dateRange = findDateRange(folder);
		const startDate = folder.userSetFromDate ?? dateRange.startDate!;
		const endDate = folder.userSetEndDate ?? dateRange.endDate!;

		const blob = await pdf(
			<PdfDocument
				expenseFolder={folder}
				totalAmount={total}
				profile={profile}
			/>,
		).toBlob();

		const base64 = await blobToBase64(blob);

		await apiService.expense.submitCreate({
			folderId: folder.id,
			pdf: {
				amount: total,
				data: base64 as string,
				description: folder.description,
				profileId: profile.id,
				id: 0,
				currencyCode: folder.currencyCode,
				createdAt: new Date().toISOString(),
				endDate: endDate,
				startDate: startDate,
			},
			sendTo: profile.sendTo!,
		});
	};

	const blobToBase64 = (blob: Blob) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});

	return (
		<Dialog open={open} onClose={onClose}>
			<CloseIconButtonComponent onClick={onClose} />
			<DialogTitle
				display={'flex'}
				alignItems={'center'}
				justifyContent={'center'}
				flexDirection={'column'}
				textAlign={'center'}
			>
				<EmailOutlinedIcon fontSize="large" />
				<Typography fontWeight={600}>
					{t('send_warning_folder')}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Stack spacing={2}>
					<Typography variant="body1" textAlign={'center'}>
						{t('no_regret')}
					</Typography>
					{!profile.sendTo && (
						<Alert severity="error" variant="standard">
							<Box
								display={'flex'}
								justifyContent={'space-between'}
								flexDirection={'row'}
								alignItems={'center'}
							>
								<Typography width={'60%'}>
									{t('no_email_address')}
								</Typography>
								<Link
									sx={{ textDecoration: 'none' }}
									component={RouterLink}
									to={'/settings'}
									color="inherit"
								>
									<Box display={'flex'} flexDirection={'row'}>
										<Typography
											sx={{
												textDecoration: 'underline',
											}}
										>
											{t('settings')}
										</Typography>
										<ArrowForwardRoundedIcon />
									</Box>
								</Link>
							</Box>
						</Alert>
					)}

					<Box
						display={'flex'}
						justifyContent={'center'}
						marginTop="0.9rem"
					>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							onClick={async () => {
								matomo.trackEvent(
									'Submit folder',
									'click',
									'Submit folder button clicked',
									folder!.id,
								);
								await submit();
							}}
							loading={isSending}
							disabled={
								!profile.sendTo ||
								(folder && concatExpenses(folder).length === 0)
							}
						>
							<CheckIcon sx={{ marginRight: '0.1rem' }} />
							{t('sign_and_send')}
						</LoadingButton>
					</Box>
				</Stack>
			</DialogContent>
		</Dialog>
	);
};

export default SendFolderDialogComponent;
